@import "ux-variables";

.mat-form-field {
  .mat-form-field-subscript-wrapper {
    padding: 0 !important;
  }

  &.mat-focused {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-outline {
          background: var(--light-color);

        }
      }
    }
  }

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-outline {
        background: var(--primary-light-color-200);
        border-radius: 8px;
        height: 52px;
      }

      ::ng-deep .mat-form-field-outline-start {
        border-radius: 8px 0 0 8px !important;
        min-width: 8px !important;
      }

      ::ng-deep .mat-form-field-outline-end {
        border-radius: 0 8px 8px 0 !important;
      }
    }
  }
}


.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--dark-color) !important;
}

.mat-form-field-appearance-outline.mat-focused {
  background: var(--light-color);

  .mat-form-field-outline-thick {
    color: var(--secondary-color-800) !important;
  }
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: var(--secondary-color-800) !important;
  background: var(--light-color);

}

mat-label {
  font-size: $font-small-regular-size;
}

mat-error {
  padding-top: 3px;
  color: var(--wrong-color) !important;
}

.disabled-overlay {
  background: rgba(55, 62, 72, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-chip-list {
  .mat-chip-list-wrapper {
    .mat-chip {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.mat-grid-tile {
  border-radius: 14px;
  box-shadow: rgb(50 50 50 / 21%) 0px 0px 8px;
}

.image-dialog {
  position: relative !important;
  max-width: 800px !important;
  height: 44vh;
  width: 100%;
  border-radius: 14px;

  .mat-dialog-container {
    height: inherit;
    padding: 0;
    background: none;
    border-radius: 14px;


    hun-dialog-with-image {
      width: inherit;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .mat-dialog-content {
        padding: 0;
        border-radius: 14px;
      }
    }
  }

  &.desktop-style {
    height: 670px;

    .mat-dialog-container {
      hun-dialog-with-image {
        .mat-dialog-content {

          width: 800px;
          height: 670px;
          max-height: none;

          hun-image-with-loader {
            //
            //height: 616px;
            //padding: 0 24px;
            //
            //img {
            //  height: 616px;;
            //}
          }
        }

      }

    }
  }
}

.share-dialog {
  max-width: 520px !important;
  max-height: 560px!important;
  height: auto;
  width: 100%;
  border-radius: 14px;
  background: var(--light-color);

  .mat-dialog-container {
    background: linear-gradient(180deg, var(--secondary-color-800) 17.71%, rgba(230, 77, 246, 0) 100%);
    border-radius: 14px;
    hun-share-modal {
      .mat-dialog-content {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.cdk-virtual-scroll-data-source-example .example-viewport {
  height: 421px;
  width: 100%;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.cdk-virtual-scroll-data-source-example {
  .example-viewport {
    .cdk-virtual-scroll-content-wrapper {
      display: flex;
      flex-direction: row;
      .example-item {
        hun-card {
          padding-right: 7px;
        }
      }
    }
  }
}

.cdk-virtual-scroll-data-source-example .example-item {
  height: 100%;
}

::ng-deep.cdk-virtual-scroll-content-wrapper {

  .example-item:last-child {

    &.last {
      hun-card {
        .mat-card {
          margin-right: 0
        }
      }
    }
  }
}

.cdk-overlay-container {
  .cdk-overlay-backdrop {
    background: var(--dark-color-200);
  }
}

.mat-flat-button {
  &.mat-primary {
    color: var(--light-color);
    background: var(--primary-color-200);
  }

  &.mat-accent {
    color: var(--primary-color-300);
    background: var(--accent-color);
  }

  &.mat-button-disabled {
    background: var(--game-button-background-unactive)!important;
    color: var(--game-button-color-unactive)!important;
  }
}
.mat-progress-spinner {
  circle {
    stroke: var(--primary-color-200);
  }
}
