@import "./ux-variables";

@mixin hun-scroll(
  $scrollHeight: 4px,
  $scrollWidth: 4px,
  $thumb-color: var(--primary-color-300),
  $display: block,
) {
  &::-webkit-scrollbar {
    width: $scrollHeight;
    height: $scrollHeight;
    margin-bottom: 10px;
    background: var(--primary-light-color-200);
    display: $display;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    width: 2px;
    height: $scrollWidth;
  }
}

@mixin squiggly-line(
  $height: 19%,
  $border-color: var(--primary-light-color-200),
  $top-background-color: var(--light-color),
  $gradient-dark:var(--primary-light-color-200),
  $gradient-light: var(--light-color),
) {
  .squiggly-background {
      height: $height;
      .background-fill {
        background: linear-gradient(180deg, $gradient-dark, $gradient-light);
      }

    .squiggly-line {
      display: flex;
      overflow: hidden;

    }

    .arrow-up {
      width: 0;
      height: 0;
      border-left: 19px solid $border-color;
      border-right: 19px solid $border-color;

      border-top: 9px solid var(--light-color);
    }
  }
}
