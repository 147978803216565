@use "palette";
@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import "style/ux-variables";
@import "style/mixins";
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light-border.css';
@import "~@angular/cdk/overlay-prebuilt.css";
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

@include mat.core();

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/rubic/static/Rubik-Regular.ttf');
}

$my-primary: mat.define-palette(palette.$hunch-primary-palette);
$my-accent: mat.define-palette(palette.$hunch-accent-palette);
$my-warn: mat.define-palette(palette.$hunch-warn-palette);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn,
  )
));

$my-custom-typography-config: mat.define-typography-config(
  $font-family: Poppins,
);
$my-custom-level: mat.define-typography-level(
  $font-weight: $font-regular-weight,
  $font-size: $font-small-size,
  $line-height: $font-regular-line-height,
);

@include mat.core($my-custom-typography-config);
@include mat.core-theme($my-theme);

@include mat.button-theme($my-theme);
@include mat.all-component-themes($my-theme);
//
//@import "style/ux-variables";
//@import "style/mixins";
@import "style/material";

:root {
  --primary-color-200: #2E0F64;
  --primary-color-300: #1D0547;
  --primary-color-400: #5D399C;
  --primary-light-color-200: #2E0F641a;
  --primary-light-color-500: #1d054780;
  --primary-accent-color: #420456;
  --secondary-color-800: #E64CF5;
  --secondary-light-color-300: #E64CF533;
  --secondary-light-color-100: #9182AC;
  --accent-color: #02FEFF;
  --light-color: #ffffff;
  --light-background: #EAE7EF;
  --light-background-200: #bfbfc2;
  --dark-color: #3232320d;
  --game-button-background-unactive: #0000001f;
  --game-button-color-unactive: #00000042;
  --dark-color-200: #00000070;
  --correct-color: #4CAF50;
  --wrong-color: #E04141;
  --facebook-color: #4267B2;
  --google-color: #EA4335;
  --leader-1: #b6e4e2;
  --leader-2: #ded6ef;
  --leader-3: #ffdd9c;
  --iframe-prompt-notification-background: #DBEDDB;
  --iframe-prompt-dark: #000000;
  --iframe-prompt-light: #FFFFFF;
  --iframe-upsell-background: #ff0000;
  --iframe-light-opacity: #FFFFFF33;
  --iframe-light-opacity-100: #00000008;
  --iframe-dark-opacity: #0000004D;
  --iframe-dark-opacity-200: #FFFFFF1A;
  --iframe-dark: #1D1D29;
  --iframe-shadow: #0000000F;
  --iframe-opacity: #00000000;
  --iframe-dark-border-opacity: #0000001A;
  --iframe-dark-border-opacity-200: #00000014;
  --iframe-dark-border-opacity-100: #0000000A;
  --iframe-primary: #661041;
  --iframe-accent: #FBA417;
  --iframe-accent-opacity: #FBA41740;
  --iframe-timer: #E83224;
  --iframe-user-avatar: #003F8C;
  --iframe-wrong: #E53123;
  --iframe-corect: #13C06C;
  --iframe-menu-active-icon-filter: invert(57%) sepia(97%) saturate(408%) hue-rotate(356deg) brightness(99%) contrast(99%);

  --mobile-background: url('assets/image/frame/background.svg');
  --desktop-background: url('assets/image/frame/background-desktop.svg');
}

html,
body {
  //height: 100%;
  //overflow-y: scroll;
}

body {
  //margin: 0;
  font-family: $fs-ux-font-family;
  font-style: normal;
  font-weight: $font-regular-weight;
  font-size: $font-small-regular-size;
  color: var(--primary-color-300) !important;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
  position: relative;
  min-height: 99vh;
  margin-bottom: 0;

  .gradient {
    background: linear-gradient(var(--dark-color-200), transparent);
  }

  .desktop-style {
    h2 {
      padding-left: 0;
    }
  }


  h2 {
    font-size: $font-heading-size;
    font-weight: $font-semi-bold-weight;
    text-align: start;
    margin-left: 5px;
    //padding-left: 10px;
  }

  .regular-button {
    font-size: $font-big-regular-size;
    font-weight: $font-primary-weight;
    height: $button-height;

    &.secondary-button {
      background: var(--primary-light-color-200);
      color: var(--primary-color-300);
    }

    &.share-button {
      .mat-button-wrapper {
        display: flex;
        height: 30px;
        padding-left: 3px;
        justify-content: space-between;
        align-items: center;
        mat-icon {
          transform: scaleX(-1);
          color: var(--light-color);
          height: auto;
          display: flex;
          justify-content: start;
        }
      }
    }
  }

  .admin-button {
    background: var(--primary-color-200);
    border-radius: 8px;
    font-weight: 700;
    color: var(--light-color);
    font-size: 14px;
  }

  .container {
    width: 90%;
    margin: 0 auto;
    display: block;

    &.desktop-style {
      width: 1133px;
    }
  }

  .mat-progress-spinner {
    display: inline-table !important;
  }

  .go-back-button {
    display: flex;
    align-items: center;
    font-size: $font-regular-size;
    display: flex;

    .icon {
      font-size: 20px;
    }
  }

  .carusel {
    display: flex;

    .mat-icon {
      height: auto;
      display: flex;
      justify-content: center;
      width: auto;
      font-size: $font-large-size;
      align-self: center;
      opacity: 0.3;
      cursor: pointer;

      &:last-of-type {
        padding-left: 3px;
      }

      &:first-of-type {
        padding-right: 3px;
      }
    }

    .carusel-container {
      display: flex;
      scroll-behavior: smooth;

      &.scrollable {
        overflow-x: scroll;
        @include hun-scroll();
      }
    }
  }

  .regular-button {
    width: 92%;
    border-radius: $button-border-radius;
  }

  .mat-chip {
    &.mat-chip-selected {
      background-color: var(--accent-color) !important;
      color: var(--primary-color-300) !important;
    }

    &.mat-standard-chip::after {
      background: var(--accent-color) !important;
    }
  }

  a {
    color: var(--primary-color-300);
    text-decoration: none;
  }

  .tag-chip {
    background: var(--primary-light-color-200);
    width: fit-content;
    height: fit-content;
    padding: 6px 12px;;
    border-radius: 17px;
    margin-top: 16px;
    display: table;

    div {
      display: table-cell;
      vertical-align: middle;
      font-size: $font-small-size;
      line-height: $font-small-line-height;
    }
  }

  .regular-font-style {
    font-size: $font-regular-size !important;
    font-weight: $font-regular-weight !important;
    line-height: $font-regular-line-height !important;
    color: var(--primary-color-200) !important;
  }

  .single-answer {
    background: var(--light-background);
    margin-bottom: 12px;
    min-height: 56px;
    border-radius: 8px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--primary-accent-color);

    &.selected {
      background: var(--secondary-color-800);
      color: var(--light-color);
    }

    span {
      font-weight: 600;
      font-size: 16px;
      padding: 5px;
      overflow-wrap: anywhere;
    }
  }


}

body::after {
  content: '';
  //height: 220px;
  display: block;
}

.spinner-container {
  display: flex;
  width: inherit;
  justify-content: center;
}
.single-item-container {
  z-index: 0;
  margin-left: 5px;
}
