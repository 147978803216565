//COLORS
$primary-color-200: #2E0F64;
$primary-color-300: #1D0547;
$primary-color-400: #5D399C;
$primary-light-color-200: #2E0F641a;
$primary-light-color-500: #1d054780;
$primary-accent-color: #420456;
$secondary-color-800: #E64CF5;
$secondary-light-color-300: #E64CF533;
$secondary-light-color-100: #9182AC;
$accent-color: #02FEFF;
$light-color: #ffffff;
$light-background: #EAE7EF;
$light-background-200: #bfbfc2;
$dark-color: #3232320d;
$game-button-background-unactive: #0000001f;
$game-button-color-unactive: #00000042;
$dark-color-200: #00000070;

$correct-color: #4CAF50;
$wrong-color: #E04141;
$facebook-color: #4267B2;
$google-color: #EA4335;
$leader-1: #b6e4e2;
$leader-2: #ded6ef;
$leader-3: #ffdd9c;

//FONT
$fs-ux-font-family: Poppins;
$font-primary-weight: 700;
$font-regular-weight: 400;
$font-medium-weight: 500;
$font-light-weight: 300;
$font-semi-bold-weight: 600;

$font-line-height: 34px;
$font-regular-line-height: 20.8px;
$font-small-line-height: 18px;
$font-XSmall-line-height: 16px;

$font-primary-size: 32px;
$font-second-primary-size: 30px;
$font-primary-small-size: 20px;
$font-small-size: 12px;
$font-XXSmall-size: 10px;
$font-XSmall-size: 11px;
$font-heading-size: 25px;
$font-regular-size: 16px;
$font-small-regular-size: 14px;
$font-big-regular-size: 18px;
$font-large-size: 36px;
$font-XLarge-size: 47px;
$font-error-size: 10.5px;

$button-border-radius: 24px;
$button-height: 46px;
$bottom-button-gap: 12px;

//OTHER
$toolbar-height: 65px;










